import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import DefaultLayout from '../layouts';
import Hero from '../components/Hero/Hero';
import SubscribeCTA from '../components/SubscribeCTA/SubscribeCTA';
import Featured from '../components/Featured/Featured';
import { getComponent } from '../utils';

const ProductsPage = ({ data }) => {
  const fetchShopifyProducts = async () => {
    const response = await fetch('/api/shopify-cart');
    const data = await response.json();

    // Handle the response here, e.g., update your state with product data
    console.log(data);
  };

  useEffect(() => {
    fetchShopifyProducts();
  }, []);

  return (
    <DefaultLayout
      pageTitle={data.contentfulHomePage.title}
      metaData={data.contentfulHomePage.metaData || undefined}
    >
      <Hero
        fullScreen
        {...data.contentfulHero}
        featured={data.contentfulHomePage.featuredMedia}
      />

      <div className="base">
        <div className="container">
          {data.contentfulHomePage.content.map(context => {
            const Component = getComponent(
              context.internal.type.replace('Contentful', '')
            );

            return <Component key={context.id} {...context} />;
          })}
        </div>

        {data.contentfulHomePage.callToAction && (
          <SubscribeCTA {...data.contentfulHomePage.callToAction} />
        )}
      </div>
    </DefaultLayout>
  );
};

export default ProductsPage;

export const query = graphql`
  {
    contentfulHero(id: { eq: "bc5f6a84-95ca-59a3-bb7f-ae89c692222e" }) {
      heading
      image {
        file {
          url
        }
      }
    }
    contentfulHomePage(contentful_id: { eq: "5VVFW1BQ2VvuJQnD1fOiet" }) {
      id
      title
      metaData {
        ... on ContentfulPageMeta {
          title
          metaDescription
          image {
            file {
              url
              details {
                image {
                  height
                  width
                }
              }
            }
          }
        }
      }
      featuredMedia {
        contentful_id
        link
        logo {
          fluid(maxWidth: 160, maxHeight: 160) {
            src
          }
        }
        mediaName
      }
      content {
        ... on ContentfulContentBlock {
          id
          title
          displayTitle
          textAlignment
          body {
            raw
            references {
              ... on ContentfulAsset {
                contentful_id
                __typename
                title
                file {
                  url
                }
              }
              ... on ContentfulEntry {
                contentful_id
                __typename
                ... on ContentfulYouTubeVideo {
                  contentful_id
                  youTubeLink
                }
              }
            }
          }
          callToAction {
            id
            buttonLink
            label
          }
          internal {
            type
          }
        }
        ... on ContentfulInstagramGallery {
          title
          id
          internal {
            type
          }
        }
      }
      callToAction {
        ... on ContentfulCtaButton {
          id
          label
          buttonLink
        }
        heading
      }
    }
  }
`;
